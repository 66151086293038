<template>
  <div class="transfers">

    <div class="wrapper wrapper-content animated fadeInDown">
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content no-borders b-r-lg">

              <!-- title -->
              <div class="row">
                <div class="col-md-4">
                  <div class="article">
                    <h1>{{ $t("multiples.transfer.other") }}</h1>
                  </div>
                </div>
              </div>

              <!-- table -->
              <div class="row">
                <div class="col-lg-12">
                  <div class="panel blank-panel">

                    <b-tabs content-class="mt-3" fill>
                      <b-tab :lazy="tab.recordsTotal < 0" v-for="tab in tabs" :title="$tabLabel(tab)">
                        <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"
                                   :registerWebsocket="tab.registerWebsocket"/>
                      </b-tab>
                    </b-tabs>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <transfer-book-modal v-model="transferModalShow" :id="transferModalId"/>
    <reservation-modal v-model="reservationModalShow" :id="reservationModalId" :type="reservationModalType"/>
    <default-reservation-cancel-modal v-model="reservationCancelModalShow"/>
    <receipt-modal v-model="receiptModalShow" :id="receiptModalId" :type="receiptModalType"/>
  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import {getTransfers} from "@/services/transfers";
import {getReservations} from "@/services/reservations";
import TransferBookModal from "@/components/transfer_book_modal";
import DefaultReservationCancelModal from "@/components/default_reservation_cancel_modal";
import ReservationModal from "@/components/service/reservation_modal";
import ReceiptModal from "@/components/receipt_modal";

export default {
  components: {ReceiptModal, ReservationModal, TransferBookModal, DefaultReservationCancelModal, Datatable},
  data() {
    return {
      transferModalId: 0,
      transferModalShow: false,
      reservationModalId: 0,
      reservationModalShow: false,
      reservationModalType: "show",
      reservationCancelModalShow: false,
      receiptModalId: 0,
      receiptModalShow: false,
      receiptModalType: 'create',
      tabs: [
        {
          id: 1,
          label: "transfers.available_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.start_shop?.street_name + ", " + item?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.end_shop?.street_name + ", " + item?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.license_plate) return value + "<br>" + "<small>" + (item.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "pickup_date_earliest", label: "pickup_since", formatter: this.$options.filters.formatDateDefault},
            {key: "pickup_date_latest", label: "pickup_until", formatter: this.$options.filters.formatDateDefault},
            {
              key: "distance",
              label: "distance",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDistance(value) + " " + this.$t("unit.km")
              }
            },
            {
              key: "duration",
              label: "duration",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDurationHours(value) + " " + this.$t('unit.hr') + " " + this.$options.filters.formatDurationMinutes(value) + " " + this.$t('unit.min')
              }
            },
            {
              key: "price",
              label: "price",
              formatter: (value, key, item) => {
                return this.$options.filters.formatPrice(item.price_client) + this.$t('unit.euro')
              }
            },
            {
              key: 'hint',
              label: "hint",
              formatter: (value, key, item) => {
                return this.$options.filters.simpleFormat(value)
              }
            },
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "open"
            const response = await getTransfers(filterQuery)
            this.tabs[0].recordsTotal = response.recordsTotal || 0
            // this.$set(this.activeTabRecordsTotalCount, 1, response.recordsTotal || 0)
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.book_this_transfer'),
                  action: this.showBookTransferModal
                }
              ]
            })
            return response
          },
          actions: [],
          registerWebsocket: ['transfers'],
          recordsTotal: -1
        },
        {
          id: 2,
          label: "transfers.reserved_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "transfer.start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
            {
              key: "transfer.distance",
              label: "distance",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDistance(value) + " " + this.$t("unit.km")
              }
            },
            {
              key: "transfer.duration",
              label: "duration",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDurationHours(value) + " " + this.$t('unit.hr') + " " + this.$options.filters.formatDurationMinutes(value) + " " + this.$t('unit.min')
              }
            },
            {
              key: "price",
              label: "price",
              formatter: (value, key, item) => {
                return this.$options.filters.formatPrice(value) + this.$t('unit.euro')
              }
            },
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "unassigned"
            const response = await getReservations(filterQuery)
            this.tabs[1].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showReservationDetailModal
                },
                {
                  label: this.$t('button.assign'),
                  action: this.showReservationEditModal
                },
                {
                  label: this.$t('button.start_protocol'),
                  action: () => {
                    window.open(entry.start_protocol_url)
                  },
                  enabled: entry.start_protocol_url
                },
                {
                  label: this.$t('button.end_protocol'),
                  action: () => {
                    window.open(entry.end_protocol_url)
                  },
                  enabled: entry.end_protocol_url
                },
                {
                  label: this.$t('receipts.manage'),
                  action: this.showReceiptModal
                },
                {
                  label: this.$t('button.cancel'),
                  action: this.showReservationCancelModal,
                  enabled: entry.cancelable
                }
              ]
            })
            return response
          },
          actions: [],
          recordsTotal: -1
        },
        {
          id: 3,
          label: "transfers.assigned_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "transfer.start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
            {
              key: "transfer.distance",
              label: "distance",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDistance(value) + " " + this.$t("unit.km")
              }
            },
            {
              key: "transfer.duration",
              label: "duration",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDurationHours(value) + " " + this.$t('unit.hr') + " " + this.$options.filters.formatDurationMinutes(value) + " " + this.$t('unit.min')
              }
            },
            {
              key: "price",
              label: "price",
              formatter: (value, key, item) => {
                return this.$options.filters.formatPrice(value) + this.$t('unit.euro')
              }
            },
            {key: "mover.name", label: "driver"},
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "assigned"
            const response = await getReservations(filterQuery)
            this.tabs[2].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showReservationDetailModal
                },
                {
                  label: this.$t('button.reassign'),
                  action: this.showReservationEditModal
                },
                {
                  label: this.$t('button.start_protocol'),
                  action: () => {
                    window.open(entry.start_protocol_url)
                  },
                  enabled: entry.start_protocol_url
                },
                {
                  label: this.$t('button.end_protocol'),
                  action: () => {
                    window.open(entry.end_protocol_url)
                  },
                  enabled: entry.end_protocol_url
                },
                {
                  label: this.$t('receipts.manage'),
                  action: this.showReceiptModal
                },
                {
                  label: this.$t('button.cancel'),
                  action: this.showReservationCancelModal,
                  enabled: entry.cancelable
                }
              ]
            })
            return response
          },
          actions: [],
          recordsTotal: -1
        },
        {
          id: 4,
          label: "transfers.closed_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "transfer.start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
            {
              key: "transfer.distance",
              label: "distance",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDistance(value) + " " + this.$t("unit.km")
              }
            },
            {
              key: "transfer.duration",
              label: "duration",
              formatter: (value, key, item) => {
                return this.$options.filters.formatDurationHours(value) + " " + this.$t('unit.hr') + " " + this.$options.filters.formatDurationMinutes(value) + " " + this.$t('unit.min')
              }
            },
            {
              key: "price",
              label: "price",
              formatter: (value, key, item) => {
                return this.$options.filters.formatPrice(value) + this.$t('unit.euro')
              }
            },
            {key: "mover.name", label: "driver"},
            {
              key: "state_description",
              label: "state",
              formatter: (value, key, item) => {
                if (typeof item?.start_protocol_url === 'string' && typeof item?.end_protocol_url === 'string') return value + '<br>' + '+ ' + this.$t('common.recorded')
                return value
              }
            },
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "removed_clustered"
            const response = await getReservations(filterQuery)
            this.tabs[3].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showReservationDetailModal
                },
                {
                  label: this.$t('button.start_protocol'),
                  action: () => {
                    window.open(entry.start_protocol_url)
                  },
                  enabled: entry.start_protocol_url
                },
                {
                  label: this.$t('button.end_protocol'),
                  action: () => {
                    window.open(entry.end_protocol_url)
                  },
                  enabled: entry.end_protocol_url
                },
                {
                  label: this.$t('receipts.manage'),
                  action: this.showReceiptModal
                },
                {
                  label: this.$t('button.cancel'),
                  action: this.showReservationCancelModal,
                  enabled: entry.cancelable
                }
              ]
            })
            return response
          },
          actions: [],
          recordsTotal: -1
        },
      ],
    }
  },
  methods: {
    showBookTransferModal(entry) {
      this.transferModalId = entry.id
      this.transferModalShow = true
    },
    showReservationDetailModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = "show"
      this.reservationModalShow = true
    },
    showReservationEditModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = "edit"
      this.reservationModalShow = true
    },
    showReservationCancelModal() {
      this.reservationCancelModalShow = true
    },
    showReceiptModal(entry) {
      this.receiptModalId = entry.id
      this.receiptModalShow = true
      this.receiptModalType = entry.allows_receipt_upload ? 'create' : 'view'
    },
  }
}
</script>